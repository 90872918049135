import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'
import { SocialIcon } from 'react-social-icons';


import logoRedondo from '../img/logo-redondo.png'
import { Navbar, Form, Nav, Container, Badge,  NavDropdown} from 'react-bootstrap'

class Menu extends Component {
  constructor() {
    super();   
  }

 
 

  render() {
    return (
      <div>
        <Navbar className="shadow" sticky="top" bg="dark" variant="dark" expand="lg">
          <Container>
            <Navbar.Brand href="/">
              <img className="img-fluid p-3" width="120" src={logoRedondo} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link href="/aefdup">AEFDUP</Nav.Link>
                <Nav.Link href="/equipa">Departamentos</Nav.Link>
                <Nav.Link href="/documentos">Documentos</Nav.Link>
                <NavDropdown title="Serviços" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/instalacoes">Instalações</NavDropdown.Item>
                  <NavDropdown.Item href="/casas">Encontra a tua casa</NavDropdown.Item>
                  <NavDropdown.Item href="/vivecomosteus">Vive com os Teus</NavDropdown.Item>
                  <NavDropdown.Item href="/exames">Exames</NavDropdown.Item>
                  <NavDropdown.Item href="/banco-sebentas">Banco de Sebentas</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="/tribuna">Tribuna</Nav.Link>  
                <Nav.Link href="/protocolos">Protocolos</Nav.Link>  
                <NavDropdown title="Loja" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/livros">Livros</NavDropdown.Item>
                  <NavDropdown.Item href="/merch">Merch</NavDropdown.Item>
                </NavDropdown>

                <Nav.Link href="/contactos">Contactos</Nav.Link>
              </Nav>
              <Form>
              <SocialIcon url="https://www.facebook.com/geralaefdup" fgColor="#fff" bgColor="#222"  />
              <SocialIcon url="https://www.youtube.com/user/AEFDUP"fgColor="#fff" bgColor="#222" />
              <SocialIcon url="https://www.instagram.com/aefdup/" fgColor="#fff" bgColor="#222" />
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>



      </div>
      )
  }
}

export default Menu;