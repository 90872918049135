import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'
import Logo from '../img/logo.png'
import { Image, Row, Col, Card, Container, Tabs, Tab, Button } from 'react-bootstrap'
import Menu from './Menu'
import Footer from './Footer';
import Banner from './Banner';

import programa from '../Plano de atividades.pdf'

function importAll(r) {
    return r.keys().map(r);
}

const acaoSocial = importAll(require.context('../img/equipa6/Ação Social', false, /\.(jpe?g|JPG|png|PNG)$/));
const cultural = importAll(require.context('../img/equipa6/Cultural e Grupos Académicos', false, /\.(jpe?g|JPG|png|PNG)$/));
const desportivo = importAll(require.context('../img/equipa6/Desportivo', false, /\.(jpe?g|JPG|png|PNG)$/));
const marketing = importAll(require.context('../img/equipa6/Marketing', false, /\.(jpe?g|JPG|png|PNG)$/));
const pedagogia = importAll(require.context('../img/equipa6/Pedagogia e Saídas Profissionais', false, /\.(jpe?g|JPG|png|PNG)$/));
const presidencia = importAll(require.context('../img/equipa6/Presidência', false, /\.(jpe?g|JPG|png|PNG)$/));
const producaoJuridica = importAll(require.context('../img/equipa6/Produção Jurídica', false, /\.(jpe?g|JPG|png|PNG)$/));
const recreativo = importAll(require.context('../img/equipa6/Recreativo', false, /\.(jpe?g|JPG|png|PNG)$/));
const suplete = importAll(require.context('../img/equipa6/Suplentes', false, /\.(jpe?g|JPG|png|PNG)$/));


function tratar(array) {

    var novoArray = [];
    for (var i = 0; i < array.length; i++) {
        var elemento = {};
        elemento.img = array[i];
        var elementosNome = (array[i].split("/")[3]).split("--");
        elemento.nome = elementosNome[1];
        elemento.cargo = elementosNome[2].split(".")[0];
        novoArray.push(elemento);

    }

    return novoArray;
}

class Equipa extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div>
                <Menu />
                <Banner titulo="Equipa" subtitulo="Conheça a nossa equipa" />
                <Container className="py-50 ">

                    <div className="text-center p-5">
                        <Button href={programa} target="_blank" variant="outline-danger">Consulta aqui o nosso plano de atividades</Button>
                    </div>

                    <Tabs defaultActiveKey="presidencia" id="menu-equipa">
                        <Tab className="py-4" eventKey="presidencia" title="Presidência e Tesouraria">

                            <Row>
                                {tratar(presidencia).map(function (object, i) {
                                    return (

                                        <Col lg={3} className="my-3">
                                            <Card className="border-0 text-center shadow">
                                                <Card.Img className="img-fluid imagemembro" src={object.img} />
                                                <Card.Body>
                                                    <Card.Title className="font-weight-bold" >{object.nome}</Card.Title>
                                                    <Card.Text>
                                                        <small>
                                                            {object.cargo}
                                                        </small>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>)
                                })}
                            </Row>

                        </Tab>

                        <Tab className="py-4" eventKey="acaoSocial" title="Ação Social">
                            <Row>

                                {tratar(acaoSocial).map(function (object, i) {
                                    return (

                                        <Col lg={3} className="my-3">
                                            <Card className="border-0 text-center shadow">
                                                <Card.Img className="img-fluid imagemembro" src={object.img} />
                                                <Card.Body>
                                                    <Card.Title className="font-weight-bold" >{object.nome}</Card.Title>
                                                    <Card.Text>
                                                        <small>
                                                            {object.cargo}
                                                        </small>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>)

                                })}
                            </Row>

                        </Tab>

                        <Tab className="py-4" eventKey="desportivo" title="Desportivo">
                            <Row>
                                {tratar(desportivo).map(function (object, i) {
                                    return (
                                        <Col lg={3} className="my-3">
                                            <Card className="border-0 text-center shadow">
                                                <Card.Img className="img-fluid imagemembro" src={object.img} />
                                                <Card.Body>
                                                    <Card.Title className="font-weight-bold" >{object.nome}</Card.Title>
                                                    <Card.Text>
                                                        <small>
                                                            {object.cargo}
                                                        </small>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>)
                                })}
                            </Row>
                        </Tab>

                        <Tab className="py-4" eventKey="cultural" title="Cultural e Grupos Académicos">

                            <Row>
                                {tratar(cultural).map(function (object, i) {
                                    return (
                                        <Col lg={3} className="my-3">
                                            <Card className="border-0 text-center shadow">
                                                <Card.Img className="img-fluid imagemembro" src={object.img} />
                                                <Card.Body>
                                                    <Card.Title className="font-weight-bold" >{object.nome}</Card.Title>
                                                    <Card.Text>
                                                        <small>
                                                            {object.cargo}
                                                        </small>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>)
                                })}
                            </Row>
                        </Tab>

                        <Tab className="py-4" eventKey="marketing" title="Marketing e Comunicação">

                            <Row>
                                {tratar(marketing).map(function (object, i) {
                                    return (
                                        <Col lg={3} className="my-3">
                                            <Card className="border-0 text-center shadow">
                                                <Card.Img className="img-fluid imagemembro" src={object.img} />
                                                <Card.Body>
                                                    <Card.Title className="font-weight-bold" >{object.nome}</Card.Title>
                                                    <Card.Text>
                                                        <small>
                                                            {object.cargo}
                                                        </small>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>)
                                })}
                            </Row>
                        </Tab>

                        <Tab className="py-4" eventKey="pedagogia" title="Pedagogia e Saídas profissionais">

                            <Row>
                                {tratar(pedagogia).map(function (object, i) {
                                    return (
                                        <Col lg={3} className="my-3">
                                            <Card className="border-0 text-center shadow">
                                                <Card.Img className="img-fluid imagemembro" src={object.img} />
                                                <Card.Body>
                                                    <Card.Title className="font-weight-bold" >{object.nome}</Card.Title>
                                                    <Card.Text>
                                                        <small>
                                                            {object.cargo}
                                                        </small>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>)
                                })}
                            </Row>


                        </Tab>

                        <Tab className="py-4" eventKey="producao" title="Produção Jurídica">

                            <Row>
                                {tratar(producaoJuridica).map(function (object, i) {
                                    return (
                                        <Col lg={3} className="my-3">
                                            <Card className="border-0 text-center shadow">
                                                <Card.Img className="img-fluid imagemembro" src={object.img} />
                                                <Card.Body>
                                                    <Card.Title className="font-weight-bold" >{object.nome}</Card.Title>
                                                    <Card.Text>
                                                        <small>
                                                            {object.cargo}
                                                        </small>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>)
                                })}
                            </Row>
                        </Tab>

                        <Tab className="py-4" eventKey="recreativo" title="Recreativo">

                            <Row>
                                {tratar(recreativo).map(function (object, i) {
                                    return (
                                        <Col lg={3} className="my-3">
                                            <Card className="border-0 text-center shadow">
                                                <Card.Img className="img-fluid imagemembro" src={object.img} />
                                                <Card.Body>
                                                    <Card.Title className="font-weight-bold" >{object.nome}</Card.Title>
                                                    <Card.Text>
                                                        <small>
                                                            {object.cargo}
                                                        </small>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>)
                                })}
                            </Row>
                        </Tab>

                        <Tab className="py-4" eventKey="suplentes" title="Suplentes">

                            <Row>
                                {tratar(suplete).map(function (object, i) {
                                    return (
                                        <Col lg={3} className="my-3">
                                            <Card className="border-0 text-center shadow">
                                                <Card.Img className="img-fluid imagemembro" src={object.img} />
                                                <Card.Body>
                                                    <Card.Title className="font-weight-bold" >{object.nome}</Card.Title>
                                                    <Card.Text>
                                                        <small>
                                                            {object.cargo}
                                                        </small>
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Col>)
                                })}
                            </Row>
                        </Tab>

                    </Tabs>

                </Container>
                <Footer />
            </div>
        )
    }
}

export default Equipa;