import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'
import Logo from '../img/logo-redondo.png'

class Manutencao extends Component {
    constructor(props) {
        super(props);
       
    }

    render() {
        return (

          <div className="login" >
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 mx-auto">
                  <div class="card card-signin mt-5">
                    <div class="card-body">
                      <div className="text-center">
                        <img src={Logo} className="py-5 img-fluid w-50 text-center" />
                      </div>

                      <p className='text-white text-center'>
                        O website encontra-se neste momento em manutenção
                      </p>
 
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        )
    }
}

export default Manutencao;