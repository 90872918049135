import React from 'react';
import ReactDOM from 'react-dom';
import Homepage from './Homepage';
import AEFDUP from './components/AEFDUP'
import servicos from './components/Servicos'
import protocolos from './components/Protocolos'
import equipa from './components/Equipa'
import livros from './components/Livros'
import Admin from './components/Admin'
import LojaItem from './components/Loja-Item';
import Contactos from './components/Contactos';
import Exames from './components/Exames'
import Casas from './components/Casas'
import Salas from './components/Salas'
import BancoSebentas from './components/BancoSebentas'
import Documentos from './components/Documentos'
import PoliticaPrivacidade from './components/PoliticaPrivacidade'
import PaginaNaoEncontrada from './components/PaginaNaoEncontrada'
import Tribuna from './components/Tribuna'
import ViveComOsTeus from './components/ViveComOsTeus';
import Merchandising from './components/Merchandising';
import Desporto from './components/Desporto';

import Manutencao from './components/Manutencao'

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import AOS from 'aos';
import Parcerias from './components/Parcerias';
AOS.init();


const routing = (
  <Router>
    <div>
    <Switch>
      <Route exact path="/" component={Homepage} />
      <Route exact path="/aefdup" component={AEFDUP} />
      <Route exact path="/instalacoes" component={servicos} />
      <Route exact path="/protocolos" component={protocolos} />
      <Route exact path="/equipa" component={equipa} />
      <Route exact path="/livros" component={livros} />
      <Route exact path="/contactos" component={Contactos} />
      <Route exact path="/salas" component={Salas} />
      <Route exact path="/admin" component={Admin} />
      <Route exact path="/exames" component={Exames} />
      <Route exact path="/casas" component={Casas} />
      <Route exact path="/banco-sebentas" component={BancoSebentas} />
      <Route exact path="/documentos" component={Documentos} />
      <Route exact path="/politica-privacidade" component={PoliticaPrivacidade} />
      <Route exact path="/tribuna" component={Tribuna} />
      <Route exact path="/vivecomosteus" component={ViveComOsTeus} />
      <Route exact path="/merch" component={Merchandising} />
      <Route exact path="/desporto" component={Desporto} />
      <Route exact path="/parcerias" component={Parcerias} />
      <Route path="/loja/:livro" component={LojaItem} />
      <Route component={PaginaNaoEncontrada} /> 
    </Switch>

    </div>
  </Router>
)
ReactDOM.render(routing, document.getElementById('root'))