import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'

import { SocialIcon } from 'react-social-icons';

import {Container, Row, Col} from 'react-bootstrap'

import logoRedondo from '../img/logo-redondo.png'

class Footer extends Component {
  
  render() {
    return (
      <div>
        <Container fluid={true} className="footer">
          <Container>
            <Row className="mb-5">
              <Col lg={3}>
              <img src={logoRedondo} className="img-fluid" width="160" />
              </Col>
              <Col lg={3}>
           
                <h3 className="font-weight-bold py-3">AEFDUP</h3>
                Associação de Estudantes da Faculdade de Direito da Universidade do Porto<br /><br />
                Rua dos Bragas, 223
                <br />
                4050-123 Porto, Portugal
                <br />
                +351 222 041 600
                <br />
                geral@aefdup.pt
                </Col>
                <Col lg={3}>
                <h3 className="font-weight-bold py-3">Menu</h3>
                <ul className="list-group">
                  <li><a href="/aefdup">AEFDUP</a></li>
                  <li><a href="/exames">Exames</a></li>
                  <li><a href="/documentos">Documentos</a></li>
                  <li><a href="/banco-sebentas">Banco de Sebentas</a></li>
                  <li><a href="/instalacoes">Instalações</a></li>
                  <li><a href="/casas">Encontra a tua casa</a></li>
                  <li><a href="/protocolos">Protocolos</a></li>
                  <li><a href="/equipa">Departamentos</a></li>
                  <li><a href="/livros">Loja</a></li>
                  <li><a href="/politica-privacidade">Política de Privacidade</a></li>
                  <li><a href="/contactos">Contactos</a></li>
                </ul>
              
              </Col>

              <Col lg={3}>
              <h3 className="font-weight-bold py-3">Social</h3>

              <SocialIcon style={{ marginRight: 10 }} url="https://www.facebook.com/geralaefdup" bgColor="#fff"  />
              <SocialIcon style={{ marginRight: 10 }} url="https://www.youtube.com/user/AEFDUP" bgColor="#fff" />
              <SocialIcon  url="https://www.instagram.com/aefdup/" bgColor="#fff" />

              </Col>
            </Row>
       
          </Container>
          <p className="text-center pt-5"> {(new Date().getFullYear())} © Todos os direitos reservados | Desenvolvido por <a target="_blank" href="https://www.behance.net/nelsonsousa">Nelson Sousa</a></p>
        </Container>
      </div>
    )
  }
}

export default Footer;
