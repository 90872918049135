import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'
import { Carousel, Button } from 'react-bootstrap'

import slide1 from '../img/slide1.jpg'
import slide2 from '../img/slide2.jpg'

import Menu from './Menu'
import Footer from './Footer';
import Banner from './Banner';


class PaginaNaoEncontrada extends Component {
  constructor() {
    super();
  }
  
  render() {
    return (
        <div>
             <Menu />
            <Banner titulo="Página não encontrada" subtitulo="A página que tentou procurar não existe" />
            
            <Footer />
        </div>
      )
  }
}

export default PaginaNaoEncontrada;