import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'
import { Modal, Form, Button, Container, Card, Col, Row, Popover, OverlayTrigger } from 'react-bootstrap'
import Menu from './Menu'
import Footer from './Footer';


import sheets from '../data/sheets'
import Papa from "papaparse";



class LojaItem extends Component {
    constructor() {
        super();
        this.state = {
            livro: {}
        };
    }

    componentWillMount() {

            Papa.parse(sheets.livros, {
              download: true,
              header: true,
              
              complete: (results) => {
        
                results.data.forEach(element => {

                element.info = element.info.replace("\\n", "\n");
        
                  if(element.emStock == "TRUE") {
                    element.emStock = true;
                  } else {
                    element.emStock = false;
                  }
        
                  if(element.show == "TRUE") {
                    element.show = true;
                  } else {
                    element.show = false;
                  }

                  element.infoSplit = element.info.split(";");

                  
                });

                let found = results.data.find(x => x.urlName === this.props.match.params.livro);

                this.setState({
                    livro: found
                });


                  console.log(this.state.livro);
              },
            });


        window.scrollTo(0, 0)
       
    }

    render() {      
        return (
            <div>
                <Menu />
                <Container className="py-50">
                    <Row>
                        <Col lg={8} >
                            <h3 className="product-title" >{this.state.livro.nome}</h3>
                            <hr />
                            {!this.state.livro.emStock &&
                                <p className="text-danger font-weight-bold">
                                    Esgotado
                                </p>
                            }
                            <h5>{this.state.livro.descricao}</h5>
                            <hr />
                            <p>
                                
                                {this.state?.livro?.infoSplit?.map( (item, key) => {
                                    if (item.includes('PARTE')) {
                                        return (
                                            <span>
                                                <b>{item}</b>
                                            </span>
                                        )
                                    } else {
                                        return (
                                            <li key={key}>
                                                {item}<br />
                                            </li>
                                        )
                                    }
                                })}
                            </p>
                            <hr />

                            {/* Botão comentado  
                            <h1>Preço: {this.state.livro.preco}€</h1>
                             */}
                            <hr />
                            
                        </Col>
                        <Col lg={4}>
                            <img className="img-fluid" src={this.state.livro.urlImagem} />
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </div>
        )
    }
}

export default LojaItem;