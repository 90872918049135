import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'
import Logo from '../img/logo.png'
import { Container, Col, Row, Image} from 'react-bootstrap'
import Menu from './Menu'
import Footer from './Footer';
import Banner from './Banner';


class Equipa extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div>
                <Menu />
                <Banner titulo="Política de Privacidade" subtitulo="Tratamento de dados" />
                <Container className="my-5">

                    <Row>
                        <Col lg={6}>
                        A <b>Associação de Estudantes da Faculdade de Direito da Universidade do Porto</b> vai proceder à recolha de determinados dados pessoais de forma a concretizar as suas encomendas e requisição de salas.
                    <br/>
                    <br/>
                    Assim, os dados recolhidos serão tratados ao abrigo do Regulamento (UE) 2016/679, de 27 de abril de 2016 (Regulamento Geral sobre a Proteção de Dados – “RGPD”), e demais legislação aplicável, por um período de no máximo dois anos, e destinam-se à gestão da relação entre V.Ex.ª e a <b>Associação de Estudantes da Faculdade de Direito da Universidade do Porto</b>, tendo em vista a sua vontade de efectuar encomendas de códigos de livros e requisição da sala de reuniões.
                    <br/>
                    <br/>
                    Os dados recolhidos serão unicamente utilizados para gestão de logísticas.
                    <br/>
                    <br/>
                    Poderá, nos termos da lei, solicitar à <b>Associação de Estudantes da Faculdade de Direito da Universidade do Porto</b>, o acesso, a retificação, a eliminação, a portabilidade dos dados, opor-se ou pedir limitação do tratamento. Pode ainda retirar o seu consentimento a qualquer altura, sendo que a retirada do consentimento não compromete a licitude do tratamento realizado com base no mesmo, previamente prestado.
                    <br/>
                    <br/>
                    Pode consultar os nosso contactos clicando <a href="/contactos">aqui</a>.
                        </Col>

                        <Col lg={6}>

                        <Image src="https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2252&q=80" className="img-fluid" />
                        </Col>
                    </Row>
                   
                </Container>
                <Footer />
            </div>
        )
    }
}

export default Equipa;