import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'
import { Container, Card, Col, Row , Form, ListGroup} from 'react-bootstrap'
import Menu from './Menu'
import Footer from './Footer';
import Banner from './Banner'
import sheets from '../data/sheets'
import Papa from "papaparse";

import { TiHomeOutline, TiUser } from "react-icons/ti";

class Casas extends Component {
    constructor() {
        super();
        this.state = {
            casas: []
          }
    }

    componentDidMount() {

        Papa.parse(sheets.casas, {
          download: true,
          header: true,
    
          complete: (results) => {
    
            results.data.reverse();
    
          
            this.setState({
              casas: results.data
            });
    
            console.log(results.data)
          },
        });
    
      }

    render() {
        return (
            <div>
                <Menu />

                <Banner titulo="Encontra a tua casa" subtitulo="Oportunidades de Alojamento" />
                <Container className="py-50">

                    <Row>

                    {this.state.casas.map((casa) =>

                        <Col sm={6}>
                            <Card className="anuncio-casa mb-3">
                                <Card.Body>
                                    <Card.Title className="font-weight-bold">Anúncio #{casa.id}</Card.Title>
                                    <hr/>
                                    <Row>
                                        <Col xs={6}>
                                            <h6>Informações Gerais</h6>
                                            <hr/>
                                            <p>
                                                <b>Tipologia: </b>{casa.tipologia}
                                            </p>
                                            <p>
                                                <b>Limitação género: </b>{casa.limitacao_genero}
                                            </p>                              
                                        </Col>

                                        <Col xs={6}>
                                            <h6>Localização e Transportes</h6>
                                            <hr/>
                                            <p>
                                                <b>Local: </b>{casa.local}
                                            </p>
                                            <p>
                                                <b>Distância à FDUP: </b>{casa.distancia_fdup}
                                            </p>
                                            <p>
                                                <b>Transportes: </b>{casa.transportes}
                                            </p>
                                        </Col>

                                    </Row>

                                    <Row className="mt-3">
                                        <Col xs={6}>
                                            <h6>Características</h6>
                                            <hr/>
                                            <p>
                                                <b>Casas de banho: </b>{casa.casa_de_banho}
                                            </p>
                                            <p>
                                                <b>Cozinha: </b>{casa.cozinha}
                                            </p>
                                            <p>
                                                <b>Eletrodomésticos: </b>{casa.eletrodomesticos}
                                            </p>
                                        </Col>

                                        <Col xs={6}>
                                            <h6>Serviços</h6>
                                            <hr/>
                                            <p>
                                                <b>Água: </b>{casa.agua}
                                            </p>
                                            <p>
                                                <b>Eletricidade: </b>{casa.eletricidade}
                                            </p>
                                            <p>
                                                <b>Gás: </b>{casa.gas}
                                            </p>
                                            <p>
                                                <b>TV: </b>{casa.tv}
                                            </p>
                                            <p>
                                                <b>Internet: </b>{casa.internet}
                                            </p>
                                        </Col>
                                    </Row>

                                    <hr/>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="6">
                                            Renda Mensal
                                        </Form.Label>
                                        <Col sm="6">
                                            <Form.Control className="text-right renda" plaintext readOnly value={casa.renda} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="6">
                                            Exigência fiador
                                        </Form.Label>
                                        <Col sm="6">
                                            <Form.Control className="text-right" plaintext readOnly value={casa.exigencia_fiador} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="6">
                                            Adiantamento de renda
                                        </Form.Label>
                                        <Col sm="6">
                                            <Form.Control className="text-right" plaintext readOnly value={casa.adiantamento_renda} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="6">
                                            Email
                                        </Form.Label>
                                        <Col sm="6">
                                            <Form.Control className="text-right" plaintext readOnly value={casa.email} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="6">
                                            Telefone
                                        </Form.Label>
                                        <Col sm="6">
                                            <Form.Control className="text-right" plaintext readOnly value={casa.telefone} />
                                        </Col>
                                    </Form.Group>

                                   
                                </Card.Body>
                            </Card>
                        </Col>

)}
                    </Row>
                </Container>
                <Footer />

            </div>
        )
    }
}

export default Casas;
