import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'

import { Form, Nav, Container, Button, Col, Row, NavDropdown } from 'react-bootstrap'
import fire from '../config/Fire'
import Swal from 'sweetalert2'
import SalaReunioes from '../img/sala-reunioes.jpg'

import Footer from '../components/Footer'
import Menu from '../components/Menu'
import Banner from '../components/Banner'

class Salas extends Component {
    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            dia: "",
            hora: "",
            nome: "",
            email: "",
            hoje: "",
            dataReserva: "",

            reservasDoDia: [],
            haRepetido: null,
        };
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    componentDidMount() {

        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();

        if (dd < 10) dd = '0' + dd
        if (mm < 10) mm = '0' + mm

        this.setState({ hoje: yyyy + '-' + mm + '-' + dd })
        this.setState({ dataReserva: dd + '/' + mm + '/' + yyyy })

        this.obterReservas();

    }

    obterReservas = () => {
        let reservas = [];
        fire.firestore().collection('reservas').get()
            .then(snapshot => {
                if (snapshot.empty) {
                    console.log('Não há reservas.');
                }
                snapshot.forEach(doc => {
                    var obj = doc.data();
                    obj.id = doc.id;
                    reservas.push(obj);

                    //console.log(obj)
                });

                this.setState({
                    reservasDoDia: reservas
                })
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
        console.log(reservas);
    }

    verificarRepetida = (dia, hora) => {

        var reservas = this.state.reservasDoDia;
        var found = false;
        for (var i = 0; i < reservas.length; i++) {
            if (reservas[i].dia == dia && reservas[i].hora == hora) {
                found = true;
                break;
            }
        }

        return found;
    }



    requisitarSala = () => {

        var date = new Date(this.state.dia)
        
        var data = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + "/"
            + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)))
            + '/' + date.getFullYear();


        var haRepetido =  this.verificarRepetida(data, this.state.hora);

        if (this.state.dia == "" || this.state.email == "" || this.state.nome == "" || this.state.hora == "") {

            Swal.fire({
                title: 'Informação em falta',
                text: 'Por favor preenche todos os campos',
                icon: 'warning',
                confirmButtonColor: 'rgba(220, 53, 69,1)',
                customClass: {
                    confirmButton: 'btn-outline-danger',
                }
            });

        } else if (haRepetido) {

    
            Swal.fire({
                title: 'Já existe uma reserva para essa hora',
                text: 'Por favor, tenta outra data',
                icon: 'error',
                confirmButtonColor: 'rgba(220, 53, 69,1)',
            });


        } else {

            let reserva = {
                nome: this.state.nome,
                email: this.state.email,
                dia: data,
                hora: this.state.hora,
                dataReserva: new Date(),
            }

            fire.firestore().collection("reservas").add(reserva)
                .then(function () {

                    Swal.fire({
                        title: 'Sala requisitada com sucesso',
                        text: reserva.dia + " - " + reserva.hora,
                        icon: 'success',
                        confirmButtonColor: 'rgba(220, 53, 69,1)',
                    });

                    Swal.fire({
                        title: 'Sala requisitada com sucesso',
                        text: reserva.dia + " - " + reserva.hora,
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: 'rgba(220, 53, 69,1)',
                        confirmButtonText: 'Ok'
                      }).then((result) => {
                        if (result.value) {
                            window.location.reload();
                        }
                      })

                });
        }
    }

    render() {
        return (
            <div>
                <Menu />
                <Banner titulo="Sala de Reuniões" subtitulo="Requisita uma sala de reuniões" />
                <Container className="py-50 ">

                    <Row className="mb-5">
                        <Col lg={6}>
                            <img src={SalaReunioes} className="img-fluid" />
                        </Col>
                        <Col lg={6}>
                            Sala adequada para reunião, com mesas e lugares sentados.
                            <br />
                            A AEFDUP, em casos urgentes, tem prioridade para reunir no espaço ainda que tenha sido reservado.
                        </Col>
                    </Row>

                    <Form.Group>
                        <Form.Control onChange={this.handleChange} name="nome" type="text" placeholder="Nome" required />
                    </Form.Group>

                    <Form.Group>
                        <Form.Control onChange={this.handleChange} name="email" type="text" placeholder="Email" required />
                    </Form.Group>

                    <Form.Group >
                        <Form.Control onChange={this.handleChange} min={this.state.hoje} name="dia" type="date" placeholder="Dia" required />
                    </Form.Group>

                    <Form.Control name="hora" onChange={this.handleChange} as="select">
                        <option selected disabled>Hora</option>
                        <option>10h - 11h</option>
                        <option>11h - 12h</option>
                        <option>12h - 13h</option>
                        <option>13h - 14h</option>
                        <option>14h - 15h</option>
                        <option>15h - 16h</option>
                        <option>16h - 17h</option>
                        <option>17h - 18h</option>
                    </Form.Control>

                    <p className="pt-5">Ao submeter a sua requisicão de sala de reuniões, está a aceitar a nossa <a href="/politica-privacidade">política de privacidade</a></p>

                    <div className="my-4 ">
                        <Button variant="danger" onClick={this.requisitarSala} type="submit" >Requisitar</Button>
                    </div>

                </Container>
                <Footer />
            </div>
        )
    }
}

export default Salas;