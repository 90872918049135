import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'
import { Tab, Nav, Col, Row, Container, Card, Table, Button } from 'react-bootstrap'
import Logo from '../img/logo-redondo.png'
import { logout } from '../config/Fire';
import fire from '../config/Fire'
import { IoMdLogOut, IoIosBook, IoIosArchive, IoMdPerson, } from "react-icons/io";
import { FaSort } from 'react-icons/fa'
import Swal from 'sweetalert2';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { faVenusDouble } from '@fortawesome/free-solid-svg-icons';


class Dashboard extends Component {
    constructor() {
        super();
        this.state = {
            encomendas: [],
            reservas: [],
            sugestoes: [],

        }
    }

    datesSorter = (a, b) => {
        if (new Date(a) < new Date(b)) return 1;
        if (new Date(a) > new Date(b)) return -1;
        return 0;
    }

    dateFormatter(cell) {
        if (!cell) {
              return "";
        }
        return new Date(cell).toLocaleDateString("pt-PT");
    }
    
   

    apagar = (id, colecao) => {

        Swal.fire({
            title: 'Tem a certeza?',
            text: "Esta ação é irrevertível",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'rgba(220, 53, 69,1)',
            cancelButtonColor: '#333',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar',
            customClass: {
                confirmButton: 'btn-outline-danger',
                cancelButton: 'btn'
            }
        }).then((result) => {

            if (result.value) {

                fire.firestore().collection(colecao).doc(id).delete();

                this.obterReservas();
                this.obterEncomendas();
                this.obterSugestoes();

                Swal.fire({
                    title: 'Apagado!',
                    text: 'Apagado com sucesso',
                    icon: 'success',
                    confirmButtonColor: 'rgba(220, 53, 69,1)',
                })
            }
        })

    }

    componentDidMount() {
        this.obterReservas();
        this.obterEncomendas();
        this.obterSugestoes();
    }

    obterEncomendas = () => {
        let encomendas = [];
        fire.firestore().collection('encomendas').orderBy('data', 'desc').get()
            .then(snapshot => {
                if (snapshot.empty) {
                    console.log('Não há reservas.');
                }
                snapshot.forEach(doc => {
                    var obj = doc.data();
                    obj.id = doc.id;
                    encomendas.push(obj);

                    obj.data = new Date((obj.data.seconds * 1000)).toLocaleDateString("pt-PT");

                    //console.log(obj.data.);
                });

                this.setState({
                    encomendas: encomendas
                })
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }

    custom_sort = (a, b) => {
        return new Date(a.data).getTime() - new Date(b.data).getTime();
    }

    obterReservas = () => {
        let reservas = [];
        fire.firestore().collection('reservas').orderBy('dataReserva', 'desc').get()
            .then(snapshot => {
                if (snapshot.empty) {
                    console.log('Não há reservas.');
                }
                snapshot.forEach(doc => {
                    var obj = doc.data();

                    obj.id = doc.id;
                    reservas.push(obj);

                    obj.dataReserva = obj.dataReserva.toDate();


                    let splitDate = obj.dia.split("/");

                    let dia = splitDate[0];
                    let mes = splitDate[1];
                    let ano = splitDate[2];

                    obj.dia = new Date(mes + "/" + dia + "/" + ano);



                });

                this.setState({
                    reservas: reservas
                })
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
        console.log(reservas);
    }

    obterSugestoes = () => {
        let sugestoes = [];
        fire.firestore().collection('sugestoes').get()
            .then(snapshot => {
                if (snapshot.empty) {
                    console.log('Não há reservas.');
                }
                snapshot.forEach(doc => {
                    var obj = doc.data();
                    obj.id = doc.id;
                    sugestoes.push(obj);

                    //console.log(obj)
                });

                this.setState({
                    sugestoes: sugestoes
                })
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
        console.log(sugestoes);
    }

    renderPaginationShowsTotal(start, to, total) {
        return (
            <p >
                {total} Reservas
            </p>
        );
    }


    render() {

        const options = {
            paginationShowsTotal: this.renderPaginationShowsTotal,  // Enable showing total text
        };


        return (
            <div>

                <Container fluid={true}>

                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col className="dashboard-sidebar py-5" sm={2}>
                                <div className="text-center">
                                    <img src={Logo} width="150" className="img-fluid pb-5" />

                                </div>
                                <Nav variant="pills" className="flex-column"x>

                                    <Nav.Item>
                                        <Nav.Link eventKey="first"><IoIosBook />&nbsp;&nbsp;&nbsp;Reservas</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="sugestoes"><IoMdPerson />&nbsp;&nbsp;&nbsp;Sugestões</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link onClick={logout} href="#"><IoMdLogOut />&nbsp;&nbsp;&nbsp;Logout</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col sm={10} className="p-5">
                                <Tab.Content >
                                    <Tab.Pane eventKey="first">
                                        <Card className="shadow border-0">
                                            <Card.Body>
                                                <Card.Title>Reservas</Card.Title>
                                                <Card.Text>

                                                    <BootstrapTable options={options} data={this.state.reservas} striped hover searchPlaceholder='Pesquisar reservas' search pagination>
                                                        <TableHeaderColumn width={'25%'} isKey dataField='dataReserva' dataSort={true} data-sorter={this.datesSorter} dataFormat={this.dateFormatter} ><FaSort />Data Reserva</TableHeaderColumn>
                                                        <TableHeaderColumn width={'25%'} dataField='email' dataSort={true} ><FaSort />Email</TableHeaderColumn>
                                                        <TableHeaderColumn width={'25%'} dataField='dia' dataSort={true} dataFormat={this.dateFormatter} ><FaSort />Dia</TableHeaderColumn>
                                                        <TableHeaderColumn width={'25%'} dataField='hora' dataSort={true}><FaSort />Hora</TableHeaderColumn>
                                                    </BootstrapTable>
                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Tab.Pane>


                                    <Tab.Pane eventKey="sugestoes">
                                        <Card className="shadow border-0">
                                            <Card.Body>
                                                <Card.Title>Sugestões</Card.Title>
                                                <Card.Text>

                                                    <BootstrapTable options={options} data={this.state.sugestoes} striped hover searchPlaceholder='Pesquisar sugestoes' search pagination>
                                                        <TableHeaderColumn width={'75%'} isKey dataField='sugestao' dataSort={true} ><FaSort />Sugestão</TableHeaderColumn>
                                                        <TableHeaderColumn width={'25%'} dataField='data' dataSort={true} ><FaSort />Data</TableHeaderColumn>
                                                    </BootstrapTable>

                                                </Card.Text>

                                            </Card.Body>
                                        </Card>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>

            </div>
        )
    }
}

export default Dashboard;