import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'

import {Container} from 'react-bootstrap'

import Menu from './Menu'
import Footer from './Footer';
import Banner from './Banner'


class Exames extends Component {
  constructor() {
    super();
  }
  
  render() {
    return (
        <div>

            <Menu />
            <Banner titulo="Exames" subtitulo="Tudo o que precisas para estudar" />
            <Container className="my-5">

                <iframe src="https://drive.google.com/embeddedfolderview?id=1v7H0PsH2NvQV8t1GyBgyoApSf18wiEOM#list" style={{width: "100%", height:"600px", border:"0"}}>

                </iframe>

            </Container>
            <Footer />

        </div>
    )
  }
}

export default Exames;