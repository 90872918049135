import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'
import Menu from './Menu'
import Footer from './Footer'
import Banner from './Banner'
import { MdPeople, MdSearch , MdCardMembership} from 'react-icons/md';
import {Container, Row, Col, Card} from 'react-bootstrap'
import historia from '../img/historia.png'

class AEFDUP extends Component {
  
  render() {
    return (
        <div>
            <Menu/>
            <Banner titulo="Sobre nós" subtitulo="Conhece-nos melhor" />
            <Container className="py-50">
            <Row>
                <Col  lg={8}>
            <p className="text-justify">
            No dia 14 de Junho de 1996, ainda no edifício do Campo Alegre, 21 estudantes aprovaram por unanimidade os Estatutos fundadores da Associação de Estudantes da Faculdade de Direito da Universidade do Porto, publicados em Diário da República no dia 15 de Janeiro do ano seguinte. Com este gesto deram-se os primeiros passos formais no sentido da constituição de uma associação vocacionada para a defesa e representação dos estudantes desta Faculdade, para a promoção da sua formação cívica, cultural, desportiva e académica, contribuindo para a integração numa comunidade coesa, para a realização pessoal e académica, para o desenvolvimento de competências reconhecidas pelo mercado e para a aquisição de valores que façam deles membros socialmente conscientes e civicamente galvanizadores das suas comunidades.
           <br/>
           <br/>
            A constituição da AEFDUP, extinguia a “pró-AEFDUP” e iniciava a história do associativismo na primeira Casa dedicada ao ensino das ciências jurídicas na Universidade do Porto, depois de uma promessa do poder adiada por quase um século. Mas este não foi se não o início do verdadeiro desafio, o de passar, de estudante para estudante, a chama da vontade de lutar por causas maiores, o de alimentar a fome e sede de justiça, que a todos aqui nos traz, o de espicaçar a irreverência despertando o sentido de responsabilidade, rasgando horizontes rumo a uma intervenção estudantil madura e crítica, pugnando por uma Faculdade que ensine juristas ponderados, humanos, cidadãos e os chame a pensar como líderes de amanhã e mais ainda, como homens livres.
            <br/>  <br/>
            O caminho já trilhado não foi fácil; juntos os estudantes desta Casa passaram por duas mudanças de instalações - uma delas a meio de uma época de exames, de biblioteca empacotada e apesar de esforços exemplares de todos eles - acolhemos fraternalmente os estudantes da primeira escola de Criminologia do país, operámos uma atribulada adequação do plano de estudos a Bolonha e inaugurámos cursos de mestrado, participámos ativamente na alteração dos Estatutos da Universidade do Porto e da Faculdade de Direito em clima de colaboração entre os representantes dos três corpos e evitando a drástica diminuição de representatividade profetizada.
            <br/>  <br/>
            Neste período criaram-se e floresceram grupos académicos ativos, dinâmicos e pioneiros - estimulados pelos recíprocos sucessos - vimos estudantes desta Casa assumir elevadas responsabilidades de liderança do movimento estudantil e sempre assistimos à metamorfose de excelentes estudantes em brilhantes juristas. Com cada meta conquistada esta Casa renovando-se cresceu, consolidando-se ganhou força e nas adversidades, batalhas e disputas adquiriu identidade: ambiciosos, somos apaixonados pelo que fazemos e aspiramos sempre a fazê-lo melhor; independentes respondemos perante os nossos colegas e pelos seus melhores interesses; íntegros, assumimos um compromisso de verdade nas palavras e de transparência nas ações; unidos, acreditamos no trabalho em equipa e na nossa responsabilidade em liderar uma comunidade estudantil unida, coesa e solidária; dialogantes, acreditamos que as palavras têm o poder de unir as pessoas e inspirar à excelência.
                </p>
                </Col>
                <Col lg={4}>
              <img src={historia} className="img-fluid" />
                </Col>
            </Row>
         
            <h1 className="title-gradient text-uppercase text-center py-100">Órgãos</h1>

            <Row > 
            <Col lg={4} >
            <Card className="shadow-lg border-0 h-100 text-center py-5" >
                <Card.Body>
                    <div className="icon-rounded">
                        <span className="icon">
                        <MdCardMembership  />
                        </span>
                    </div>
                  <Card.Title  className="font-weight-bold text-uppercase" >Direcção</Card.Title>
                 <Card.Text>
                 A Direção da AEFDUP é, de acordo com os seus estatutos, o órgão executivo e de gestão corrente da Associação. 

 <br/>
 <br/>

São suas competências essenciais a administração do património da AEFDUP, o cumprimento do programa de atividades, a representação interna e externa da AEFDUPe a apresentação de um relatório de contas transparente e que traduza uma política de rigor e saneamento das suas finanças.
                 </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} >
              <Card className="shadow-lg border-0 h-100 text-center py-5" >
                <Card.Body> 
                <div className="icon-rounded">
                        <span className="icon">
                        <MdPeople  />
                        </span>
                    </div>
                  <Card.Title  className="font-weight-bold text-uppercase" >Reunião geral de alunos</Card.Title>
                                <Card.Text>
                                </Card.Text>
                                A Reunião Geral de Alunos é o meio privilegiado de contacto entre a Direção e a comunidade discente.
               
 <br />
                                <br />

                                É sede de importantes decisões, sendo o orgão deliberativo máximo, onde podes apresentar as tuas propostas e ser parte ativa da vida associativa da FDUP!
                                
                                
                                
<br />
                                <br />

                                Não te demitas do teu papel na condução da vida associativa
                </Card.Body>
              </Card>
            </Col>
            <Col lg={4} >
            <Card className="shadow-lg border-0 h-100 text-center  py-5" >
                <Card.Body>
                <div className="icon-rounded">
                        <span className="icon">
                        <MdSearch  />
                        </span>
                    </div>
                  <Card.Title className="font-weight-bold  text-uppercase" >Conselho Fiscal</Card.Title>
                  <Card.Text>
                  O Conselho Fiscal é responsável pela fiscalização da gestão financeira e patrimonial da AEFDUP.

 <br/>
 <br/>

Garante o acompanhamento da atividade financeira da associação e assegura o cumprimento das normas de boa gestão e dos orçamentos apresentados. 

<br/>
 <br/>


O Conselho Fiscal é um órgão independente de qualquer outro, na estrutura da AEFDUP, garantido a sua isenção nas decisões tomadas e nos seus procedimentos.
                </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

            </Container>
            <Footer/>
        </div>
      )
  }
}

export default AEFDUP;