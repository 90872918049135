import React, { Component, useState } from 'react';
import './stylesheets/bootstrap.css';
import './stylesheets/main.css'
import Menu from './components/Menu'
import Slide from './components/Slide'
import Footer from './components/Footer'
import Historia from './img/slide1.jpg'
import Equipa from './img/equipa/equipa.jpg'
import { Container, Row, Col, Card , Button, Form, Image} from 'react-bootstrap'
import menina from './img/menina.png'
import logo from './img/logo-redondo.png'
import manutencao from './img/manutencao.png'
import fire from './config/Fire'
import 'aos/dist/aos.css'; 
import Swal from 'sweetalert2'
import { SocialIcon } from 'react-social-icons';
import insta1 from './img/instagram/imagem1.jpg'
import insta2 from './img/instagram/imagem2.jpg'
import insta3 from './img/instagram/imagem3.jpg'
import insta4 from './img/instagram/imagem4.jpg'
import Papa from "papaparse";
import sheets from './data/sheets'

class Homepage extends Component {

  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      password: "",
      sugestao: "",
      homePageInfo: []
    };
}

componentDidMount() {

  Papa.parse(sheets.homepage, {
    download: true,
    header: true,
    
    complete: (results) => {

      this.setState({
        homePageInfo: results.data
      });

      console.log(results.data)
    },
  });

}

handleChange(e) {
  this.setState({ [e.target.name]: e.target.value });
}

enviarSugestao = (e) => {

  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();

  if (dd < 10) dd = '0' + dd
  if (mm < 10) mm = '0' + mm

  today =  dd + '/' + mm + '/' + yyyy;

  e.preventDefault();

  if(this.state.sugestao == "" ) {
    
    Swal.fire({
      title: 'Informação em falta',
      text: 'Por favor preenche o campo',
      icon: 'warning',
      confirmButtonColor: 'rgba(220, 53, 69,1)',
      customClass: {        
        confirmButton: 'btn-outline-danger',
       
      }
    });

  } else {

    this.setState({ show: false })

    let sugestao =  {
      sugestao: this.state.sugestao,
      data: today,
    }

    fire.firestore().collection("sugestoes").add(sugestao)
    .then(function () {
    
      Swal.fire({
        title: 'Sugestao enviada com sucesso',
        icon: 'success',
        confirmButtonColor: 'rgba(220, 53, 69,1)',
        
      });

    });
  }

}

  
  render() {

    return (

      <div>
      {this.state.password == "" ?  <div>
       <Menu/>
       <Slide/>
        <Container className="py-100">
          <Row>
              {this.state.homePageInfo.map(item => (       
                <Col lg={4} >
                  <Card className="shadow-lg border-0 h-100" data-aos="fade-up" >
                    <Card.Img className="card-img img-card-home" variant="top" src={item.img} />
                    <Card.Body>
                      <Card.Title className="font-weight-bold" >
                        {item.titulo}
                      </Card.Title>
                      <Card.Text>
                        {item.desc}
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="text-center">
                    <Button href={item.redirect}   variant="danger">Saber mais</Button>
                    </Card.Footer>
                  </Card>
                </Col>        
              ))}       
          </Row>
        </Container>
        <Container  className="py-100" >
          <Row>
            <Col lg={6} className="pl-5" data-aos="fade-right" >
              <h1 className="title-gradient" >AEFDUP - NOTA HISTÓRICA</h1>
              <p>
                Quatro anos após a fundação da Universidade do Porto, é apresentada à Câmara dos Deputados a criação da Faculdade de Direito.
                <br />
                <br />
                Estamos em 1915 mas a conjuntura que rodeia aquela universidade não se demonstra propícia à criação de uma faculdade de Direito pública na cidade do Porto, razão pela qual o projeto permaneceu na gaveta ao longo de boa parte do século XX.
                <br />
                <br />
                Nova espera. Agora, até ao advento da autonomia universitária: é no seu legítimo uso que o Senado, a 12 de Dezembro de 1991, cria a Faculdade de Direito. A Universidade, a Cidade, o Norte do país aguardam mais três anos até ao despacho autorizatório do Ministério.
                <br />
                <br />
                O ano lectivo 1994-1995 é consagrado à constituição da Comissão Instaladora, presidida pelo Reitor Professor Doutor Alberto Amaral, e à preparação do primeiro ano de funcionamento da Faculdade, o ano académico de 1995-1996. Abre-se o período instituinte da FDUP.
                </p>
            </Col>
            <Col lg={6} data-aos="fade-left" >
              <img src={Historia} className="img-fluid" />
            </Col>
          </Row>
        </Container>

        <Container  className="py-100" >
          <Row>
          <Col lg={6} className="pb-5" data-aos="fade-right" >
              <img src={Equipa} className="img-fluid" />
            </Col>
            <Col lg={6}  data-aos="fade-left" >
              <h1 className="title-gradient" >DEIXA O TEU CONTRIBUTO</h1>
              <p>
                  <br />
                  <Form>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Deixa aqui a tua sugestão</Form.Label>
                      <Form.Control name="sugestao" onChange={this.handleChange} as="textarea" rows="3" />
                    </Form.Group>
                    <Button variant="danger" onClick={this.enviarSugestao} type="submit">
                    Enviar
                    </Button>
                  </Form>
                  <br />
                <br />
                Sabias que uma vez por semestre, abrimos o "Associa-te", onde, mediante necessidade departamental, abrimos vagas para colaboradores, estudantes que podem ser membros ativos da Associação?
                </p>
            </Col>
            
          </Row>

          </Container>

          <Container className="my-5 " fluid={true} >

            <div className="text-center py-5">
              <h2>Segue-nos no instagram  <SocialIcon url="https://www.instagram.com/aefdup/"  fgColor="#fff" bgColor="#721c24"  /></h2>
            </div>



            <Row  data-aos="zoom-in-up" >
              <Col lg={3}>
                <img src={insta1} className="img-fluid" />
              </Col>
              <Col lg={3}>
                <img src={insta2} className="img-fluid" />
              </Col>
              <Col lg={3}>
                <img src={insta3} className="img-fluid" />
              </Col>
              <Col lg={3}>
                <img src={insta4} className="img-fluid" />
              </Col>
            </Row>
          
          </Container>

          <Container fluid={true} className="cartao">

          <Container>

            <Row lg={6} className="py-5" data-aos="fade-up-right" >
              <Col>
                <img className="img-fluid" src={menina} height="450" />
              </Col>

              <Col lg={6} className="py-5" data-aos="fade-up-left">
                <h2 className="font-weight-bold">+Ativo com a tua AE</h2>
                <p>
                  Sabias que com a tua Associação de Estudantes podes usufruir de 5% de desconto no Programa UPFit da Universidade do Porto?
                  <br />
                  <br />
                  O Programa UPFit, programa de fitness da Universidade do Porto, disponibiliza mais de 40 sessões de fitness, de segunda a sábado, em dois dos três polos da U.Porto: o Pólo I – CDUP-Boa Hora (Rua da Boa Hora nº 26) e o Pólo II – Faculdade de Desporto.
                  <br />
                  <br />
                  <h3 className="font-weight-bold">Para utilizares o desconto basta apresentares o nosso código aefdup1920 nos balcões das instalações desportivas.</h3>
                </p>
                <Button href="/protocolos" variant="outline-light" className="my-4">Saber mais</Button>
              </Col>
            </Row>
          </Container>  
              
        </Container>
       <Footer/>
        </div> :
          <div className="login manutencao" >
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-7 col-lg-5 mx-auto">
                  <div class="card card-signin mt-5">
                    <div class="card-body text-center p-5">
                    <img className="img-fluid pb-5"  width="200" src={logo} />
                    <br/>

                      <img className="img-fluid pb-5" width="100" src={manutencao} />
                      
                      <h1>Site em Manutenção</h1>
                     
                      <br />
                      <br />
                      <div className="form-label-group">
                        <input onChange={this.handleChange} type="password" id="inputPassword" name="password" className="form-control" placeholder="Password" required />
                        <label for="inputPassword">Password</label>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>
        }





      </div>
      )
  }
}

export default Homepage;