import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'
import { Container, Card, Col, Row, Form, ListGroup } from 'react-bootstrap'
import Menu from './Menu'
import Footer from './Footer';
import Banner from './Banner'
import sheets from '../data/sheets'
import Papa from "papaparse";

class Merchandising extends Component {
  constructor() {
    super();
    this.state = {
      merch: []
    }
  }

  componentDidMount() {

    Papa.parse(sheets.merch, {
      download: true,
      header: true,

      complete: (results) => {


        results.data.forEach(element => {

          if(element.emStock.toUpperCase() == "TRUE") {
            element.emStock = true;
          } else if(element.emStock.toUpperCase() == "FALSE") {
            element.emStock = false;
          }          
        });
  

        this.setState({
          merch: results.data
        });

      },
    });

  }

  render() {
    return (
      <div>
        <Menu />

        <Banner titulo="Merchandising" subtitulo="Representa a tua casa" />
        <Container className="py-50">


          <Row>

            {this.state.merch.map((merch) =>

              <Col sm={4} className="mb-4">
                <Card className="shadow border-0" >
                  <Card.Img  variant="top" src={merch.imagem} />
                  <hr/>
                  <Card.Body>
                    <Card.Title className='font-weight-bold'>{merch.nome}</Card.Title>
                    <Card.Text>
                      {merch.tamanhos} <br/>
                      {merch.emStock ? <p className="text-success">Em stock</p> :  <p className="text-danger">Indisponível</p>}
                   
               
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <Footer />

      </div>
    )
  }
}

export default Merchandising;
