import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'

import Menu from './Menu'
import Footer from './Footer';
import Banner from './Banner'
import { SocialIcon } from 'react-social-icons';

import {Container, Row, Col} from 'react-bootstrap'


class Contactos extends Component {

  
  render() {
    return (
      <div>
        <Menu />
        <Banner titulo="Contactos" subtitulo="Fale connosco" />
        <Container>
          <Row className="p-5">
            <Col className="py-5" sm={6}>
              <h4> Rua dos Bragas, 223</h4>
              4050-123 Porto, Portugal
              <br />
              +351 222 041 600
              <br />
              geral@aefdup.pt
              <br/>
              <br/>
              <SocialIcon style={{ marginRight: 10 }} url="https://www.facebook.com/geralaefdup" bgColor="#222"  />
              <SocialIcon style={{ marginRight: 10 }} url="https://www.youtube.com/user/AEFDUP" bgColor="#222" />
              <SocialIcon  url="https://www.instagram.com/aefdup/" bgColor="#222" />
          </Col>
            <Col sm={6}>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3004.1401277977643!2d-8.616965934393301!3d41.15329356913939!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9de684956c319a09!2sAEFDUP!5e0!3m2!1spt-PT!2spt!4v1578581337890!5m2!1spt-PT!2spt" width="600" height="450" frameborder="0" allowfullscreen=""></iframe>
            </Col>
          </Row>
        </Container>


        <Footer />
      </div>
    )
  }
}

export default Contactos;