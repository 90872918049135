import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'
import Logo from '../img/logo.png'
import { Container, Card, Col, Row , Image} from 'react-bootstrap'
import Menu from './Menu'
import Footer from './Footer';
import Banner from './Banner'
import ReactCardCarousel from "react-card-carousel";


function importAll(r) {
    return r.keys().map(r);
}

const viveComOsTeusImagens = importAll(require.context('../img/vivecomosteus', false, /\.(jpe?g|JPG|png|PNG)$/));

class ViveComOsTeus extends Component {
    constructor() {
        super();
    }

    static get CONTAINER_STYLE() {
        return {
            position: "relative",
            height: "40vh",
            width: "100%",
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "middle"
        };
    }

    static get CARD_STYLE() {
        return {
            height: "auto",
            width: "500px",
            textAlign: "center",
            color: "#FFF",
            fontFamily: "sans-serif",
            fontSize: "12px",
            textTransform: "uppercase",
            borderRadius: "10px",
            boxSizing: "border-box"
        };
    }

    render() {
        document.body.style.backgroundColor = "#ffcf60";

        return (
            <div>
                <Menu />
                <Container>

                    <br />
                    <br /> 

                    <Row className="mb-4">
                        <Col xs={12} md={12}>
                            <Image src={viveComOsTeusImagens[0]} className="img-fluid"r ounded />
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col xs={12} md={4}>
                            <Image src={viveComOsTeusImagens[1]} className="img-fluid"r ounded />
                        </Col>
                        <Col xs={12} md={4}>
                            <Image src={viveComOsTeusImagens[4]} className="img-fluid"r ounded />
                        </Col>
                        <Col xs={12} md={4}>
                            <Image src={viveComOsTeusImagens[6]} className="img-fluid"r ounded />
                        </Col>
                    </Row>


                    <Row className="mb-4">
                        <Col xs={12} md={6}>
                            <Image src={viveComOsTeusImagens[2]} className="img-fluid"r ounded />
                        </Col>
                        <Col xs={12} md={6}>
                            <Image src={viveComOsTeusImagens[3]} className="img-fluid"r ounded />
                        </Col>
                       
                    </Row>

                    <Row className="mb-4">
                        <Col xs={12} md={4}>
                            <Image src={viveComOsTeusImagens[8]} className="img-fluid"r ounded />
                        </Col>
                        <Col xs={12} md={4}>
                            <Image src={viveComOsTeusImagens[9]} className="img-fluid"r ounded />
                        </Col>
                        <Col xs={12} md={4}>
                            <Image src={viveComOsTeusImagens[10]} className="img-fluid"r ounded />
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col xs={12} md={6}>
                            <Image src={viveComOsTeusImagens[5]} className="img-fluid"r ounded />
                        </Col>
                        <Col xs={12} md={6}>
                            <Image src={viveComOsTeusImagens[7]} className="img-fluid"r ounded />
                        </Col>
                       
                    </Row>

                    <Row className="mb-4">
                        <Col xs={12} md={4}>
                            <Image src={viveComOsTeusImagens[11]} className="img-fluid"r ounded />
                        </Col>
                        <Col xs={12} md={4}>
                            <Image src={viveComOsTeusImagens[12]} className="img-fluid"r ounded />
                        </Col>
                        <Col xs={12} md={4}>
                            <Image src={viveComOsTeusImagens[13]} className="img-fluid"r ounded />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={4}>
                            <Image src={viveComOsTeusImagens[14]} className="img-fluid"r ounded />
                        </Col>
                        <Col xs={12} md={4}>
                            <Image src={viveComOsTeusImagens[15]} className="img-fluid"r ounded />
                        </Col>
                        <Col xs={12} md={4}>
                            <Image src={viveComOsTeusImagens[16]} className="img-fluid"r ounded />
                        </Col>
                    </Row>






                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />


                </Container>
                <Footer />

            </div>
        )
    }
}

export default ViveComOsTeus;