const sheets = {
  homepage: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSkuJXcyWyNUbfKs1uvSEVy_qhnhimqDhmmpv8ix8UKJ4wDWSP1PZNTpj0KnKjKaDyz20XIpKPdcA2C/pub?gid=268357534&single=true&output=csv",
  casas: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSkuJXcyWyNUbfKs1uvSEVy_qhnhimqDhmmpv8ix8UKJ4wDWSP1PZNTpj0KnKjKaDyz20XIpKPdcA2C/pub?gid=0&single=true&output=csv",
  livros: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSkuJXcyWyNUbfKs1uvSEVy_qhnhimqDhmmpv8ix8UKJ4wDWSP1PZNTpj0KnKjKaDyz20XIpKPdcA2C/pub?gid=941778907&single=true&output=csv",
  tribuna: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSkuJXcyWyNUbfKs1uvSEVy_qhnhimqDhmmpv8ix8UKJ4wDWSP1PZNTpj0KnKjKaDyz20XIpKPdcA2C/pub?gid=893181556&single=true&output=csv",
  documentos: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSkuJXcyWyNUbfKs1uvSEVy_qhnhimqDhmmpv8ix8UKJ4wDWSP1PZNTpj0KnKjKaDyz20XIpKPdcA2C/pub?gid=750358437&single=true&output=csv",
  merch: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSkuJXcyWyNUbfKs1uvSEVy_qhnhimqDhmmpv8ix8UKJ4wDWSP1PZNTpj0KnKjKaDyz20XIpKPdcA2C/pub?gid=657225142&single=true&output=csv",
  paginas: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSkuJXcyWyNUbfKs1uvSEVy_qhnhimqDhmmpv8ix8UKJ4wDWSP1PZNTpj0KnKjKaDyz20XIpKPdcA2C/pub?gid=485143598&single=true&output=csv"
}
export default sheets;

