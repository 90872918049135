import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'
import Logo from '../img/logo-redondo.png'
import {  Card, Row, Col, Navbar, NavDropdown, Form, Nav, Container, Button } from 'react-bootstrap'
import fire  from '../config/Fire'

import Swal from 'sweetalert2'

class Admin extends Component {
    constructor(props) {
        super(props);
        this.login = this.login.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
          email: "",
          password: ""
        };
    }

    handleChange(e) {
      this.setState({ [e.target.name]: e.target.value });
    }


    login = (e) => {
      e.preventDefault();

      this.setState({
        houveErro: false,
      })
  
      fire.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then((user) => {
      }).catch((error) => {

        Swal.fire({
          title: 'Ocorreu um erro!',
          text: error,
          icon: 'error',
          confirmButtonColor: 'rgba(220, 53, 69,1)',
        });

        console.log(error)        
      });
    }


    render() {
        return (

          <div className="login" >
            <div className="container">
              <div className="row">
                <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                  <div class="card card-signin mt-5">
                    <div class="card-body">
                      <div className="text-center">

                        <img src={Logo} className="py-5 img-fluid w-50 text-center" />
                      </div>

                     
                      <form className="form-signin">
                        <div className="form-label-group">
                          <input onChange={this.handleChange} type="email" id="inputEmail" name="email" className="form-control" placeholder="Email address" required autofocus />
                          <label for="inputEmail">Email</label>
                        </div>

                        <div className="form-label-group">
                          <input onChange={this.handleChange} type="password" id="inputPassword" name="password" className="form-control" placeholder="Password" required />
                          <label for="inputPassword">Password</label>
                        </div>


                                        <button onClick={this.login} className="btn btn-lg  btn-block text-uppercase btn-login" type="submit">Entrar</button>
                                        <div className="text-center py-5">
                                            <a className="" href="/">Voltar ao site</a>

                                        </div>


                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        )
    }
}

export default Admin;