import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'
import { Container } from 'react-bootstrap'
import Menu from './Menu'
import Footer from './Footer';
import Banner from './Banner';
import sheets from '../data/sheets'
import Papa from "papaparse";

class Parcerias extends Component {

    constructor() {
        super();
        this.state = {
            paginaContent: {},
        };
    }

    componentDidMount() {
        Papa.parse(sheets.paginas, {
            download: true,
            header: true,

            complete: (results) => {

                let pagina = results.data.find(pagina => pagina.id == "parcerias");

                console.log(pagina)
                
                this.setState({
                    paginaContent : pagina
                });
            },
        });
    }


    render() {
        return (
            <div>
                <Menu />
                <Banner titulo={this.state.paginaContent.titulo} subtitulo={this.state.paginaContent.subtitulo} />
                <Container className="my-5 pagina-content">

                <div dangerouslySetInnerHTML={{ __html: this.state.paginaContent.conteudo }} />

                </Container>
                <Footer />

            </div>
        )
    }
}

export default Parcerias;