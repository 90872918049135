import * as firebase from 'firebase';
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/firestore'
import 'firebase/storage'

const config = { 
  apiKey: "AIzaSyBaO-Ywu5uxZh-rAcJwvtaj0atsTnMda40",
  authDomain: "aefdup-41334.firebaseapp.com",
  databaseURL: "https://aefdup-41334.firebaseio.com",
  projectId: "aefdup-41334",
  storageBucket: "aefdup-41334.appspot.com",
  messagingSenderId: "817388583349",
  appId: "1:817388583349:web:b241e6889a34e5edcbc1fc"
};

const fire = firebase.initializeApp(config);

// Initialize Cloud Functions through Firebase
//firebase.functions();

export default fire;

export const logout = () => {
  fire.auth().signOut();
}

