import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'

import { Container, Badge, Col, Row, ListGroup, Button } from 'react-bootstrap'
import { TiDocument } from "react-icons/ti";

import Menu from './Menu'
import Footer from './Footer';

import logo from '../img/logo-tribuna.png'

import sheets from '../data/sheets'
import Papa from "papaparse";

class Tribuna extends Component {
  constructor() {
    super();
    this.state = {
      tribunas: []
    }
  }

  componentDidMount() {

    Papa.parse(sheets.tribuna, {
      download: true,
      header: true,

      complete: (results) => {

        results.data.reverse();

        results.data.forEach(element => {
          if(element.novo.toUpperCase() == "TRUE") {
            element.novo = true
          } else if(element.novo.toUpperCase() == "FALSE"){
            element.novo = false;
          }
        });
        this.setState({
          tribunas: results.data
        });

        console.log(results.data)
      },
    });

  }

  render() {
    return (
      <div>
        <Menu />
        <div>
          <div className="banner-tribuna">
            <Container>
              <img className="img-fluid" src={logo} />
            </Container>
          </div>
        </div>
        <Container className="my-5 text-center">
          <div class="p-5">
            <span class="p-2">
              <Button class="m-4" href="https://tribunajornal.wixsite.com/fdup" target="_blank" variant="outline-danger">Site Oficial</Button>
            </span>
            <span class="p-2">
              <Button class="m-4" href="https://www.facebook.com/tribunafdup/" target="_blank" variant="outline-danger">Facebook</Button>
            </span>
            <span class="p-2">
              <Button class="m-4" href="https://instagram.com/tribunajornal?igshid=s7zegpi203kt" target="_blank" variant="outline-danger">Instagram</Button>
            </span>
          </div>
          <Row className="documentos">
            <Col className="col-6 mx-auto">
              <ListGroup variant="flush" >
                {this.state.tribunas.map((element) =>
                  <ListGroup.Item>
                    <a target="_blank" href={element.link}><TiDocument size={20} />
                      {element.titulo}

                      {element.novo ?   <Badge className="ml-1" variant="danger">Novo</Badge>: null }
                    
                      </a>
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Col>
          </Row>
          {/* 
                <object data={pdf3} type="application/pdf" width="100%" height="700px">
                    <p>Tribuna <a href={pdf3}>PDF!</a></p>
                </object>
                <object data={pdf2} type="application/pdf" width="100%" height="700px">
                    <p>Tribuna <a href={pdf2}>PDF!</a></p>
                </object>

                <object data={pdf} type="application/pdf" width="100%" height="700px">
                    <p>Tribuna <a href={pdf2}>PDF!</a></p>
                </object>

                */}
        </Container>
        <Footer />

      </div>
    )
  }
}

export default Tribuna;
