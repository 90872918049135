import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'
import { Modal, Form, Button, Container, Card, Col, Row, Popover, Table } from 'react-bootstrap'
import Menu from './Menu'
import Footer from './Footer';
import Banner from './Banner'

import fire from '../config/Fire'
import Swal from 'sweetalert2'

import livros from '../data/Loja'

import sheets from '../data/sheets'
import { Link } from 'react-router-dom'
import Papa from "papaparse";

class Livros extends Component {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      email: '',
      nome: '',
      morada: '',
      NIF: '',
      livro: '',
      preco: 0,
      show: false,
      livros: [],
    };
  }

  componentDidMount() {

    Papa.parse(sheets.livros, {
      download: true,
      header: true,
      
      complete: (results) => {

        results.data.forEach(element => {

          if(element.emStock == "TRUE") {
            element.emStock = true;
          } else {
            element.emStock = false;
          }

          if(element.show == "TRUE") {
            element.show = true;
          } else {
            element.show = false;
          }
          
        });
  
        this.setState({
          livros: results.data
        });
  
        console.log(results.data)
      },
    });
  
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  change = (event) => {
    var livro = event.target.options[event.target.options.selectedIndex].value;

    var preco = event.target.options[event.target.options.selectedIndex].getAttribute('data-key');


    this.setState({ livro: livro })
    this.setState({ preco: preco })

  };

  handleShow = () => {
    this.setState({ show: true })
  }

  handleClose = () => {
    this.setState({ show: false })
  }

  fazerEncomenda = () => {
    var date = new Date();
    var data = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + "/"
      + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)))
      + '/' + date.getFullYear();

    if (this.state.nome == "" || this.state.email == "" || this.state.livro == "" || this.state.NIF == "" || this.state.morada == "") {
      Swal.fire({
        title: 'Informação em falta',
        text: 'Por favor preenche todos os campos',
        icon: 'warning',
        confirmButtonColor: 'rgba(220, 53, 69,1)',
      });
    } else {

      this.setState({ show: false })

      let encomenda = {
        nome: this.state.nome,
        email: this.state.email,
        livro: this.state.livro,
        data: new Date(),
        NIF: this.state.NIF,
        morada: this.state.morada,
      };

      fire.firestore().collection("encomendas").add(encomenda)
        .then(function () {
          Swal.fire({
            title: 'Encomenda efetuada com sucesso',
            text: 'Fica atento ao teu email. Brevemente enviaremos informações',
            icon: 'success',
            confirmButtonColor: 'rgba(220, 53, 69,1)',
          });

        });
    }
  }

  onChangeBook(event) {
    alert(event.target.value);
  }


  render() {

    return (
      <div>
        <Menu />
        <Banner titulo="Livros" subtitulo="Os nossos livros" />
        <Container className="py-5">

          {/* Botão comentado
          <div className="text-center py-4">
            <Button onClick={this.handleShow} variant="outline-danger">Encomendar Código</Button>
          </div>
          */}

          <Modal size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered show={this.state.show} onHide={this.handleClose}>
            <Modal.Header className="encomendar-popup-title" closeButton>
              <Modal.Title>Encomendar Código</Modal.Title>
            </Modal.Header>
            <Modal.Body className="encomendar-popup">
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Control onChange={this.handleChange} name="nome" type="text" placeholder="Nome" required />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Control onChange={this.handleChange} name="morada" type="text" placeholder="Morada" required />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Control onChange={this.handleChange} name="email" type="email" placeholder="Email" required />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Control onChange={this.handleChange} name="NIF" type="text" placeholder="NIF" required />
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control onChange={this.change} as="select">
                  <option selected disabled>Livro</option>
                  {livros.map((livro) =>
                    <option data-key={livro.preco} disabled={!livro.emStock}>{livro.nome}</option>
                  )}
                </Form.Control>
              </Form.Group>




              <p className="pt-2 text-left" >Deverás enviar o comprovativo de pagamento no prazo de 48 horas para <b>editorial@aefdup.pt</b></p>
              <p className="text-left">O pagamento deve ser realizado para o seguinte IBAN: PT50 0035 0206 00009658230 29 </p>
              <p className="text-left"><small>Ao preço referente ao Código encomendado acresce o valor de 3,5€ referente aos portes de envio.</small></p>

              {this.state.livro == '' ? '' :

                <Table className="mt-2 mb-6" responsive>
                  <thead>
                  </thead>
                  <tbody>
                    <tr >
                      <td className="w-75"> {this.state.livro}</td>
                      <td className="w-25 ">{this.state.preco}€</td>
                    </tr>
                    <tr>
                      <td className="w-75" >Portes</td>
                      <td className="w-25 " >3.5€</td>
                    </tr>
                    <tr>
                      <td className="w-75"><b>TOTAL</b></td>
                      <td className="w-25">{parseFloat(this.state.preco) + 3.50}€</td>
                    </tr>
                  </tbody>
                </Table>
              }
              <p className="pb-2 text-center"><small>Ao submeter a sua encomenda, está a aceitar a nossa <a href="/politica-privacidade">política de privacidade</a></small></p>
              <div className="text-center">
                <Button className="mr-2" variant="danger" onClick={this.fazerEncomenda} type="submit" >
                  Encomendar
                </Button>
                <Button variant="dark" onClick={this.handleClose}>
                  Fechar
                </Button>
              </div>

            </Modal.Body>
          </Modal>
          <Row>
            {this.state.livros.map((livro) =>
              <Col className="my-4" sm={4}>
                {livro.mostrar &&
                  <Card className="text-center h-100">

                    <Link className="saber-mais" to={{ pathname: `/loja/${livro.urlName}` }}>
                      <Card.Img variant="top img-card" src={livro.urlImagem} />
                    </Link>

                    <Card.Body>
                      <Card.Title className="font-weight-bold">{livro.nome}</Card.Title>
                      <Card.Text>
                        
                        {livro.descricao.substring(0, 150)}             

                        {livro.descricao.length > 150 && "..." }

                        {/* Botão comentado  
                        {!livro.emStock &&
                          <p className="text-danger font-weight-bold">
                            <hr />
                            Esgotado
                          </p>
                        }
                         */}
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="text-muted">
                      
                      {/* Botão comentado  
                      {livro.emStock &&
                        <Button className="mr-3" variant="outline-danger">{livro.preco}€</Button>
                      }
                      */}
                      <Link className="saber-mais" to={{ pathname: `/loja/${livro.urlName}` }}>
                        <Button variant="dark">Saber mais</Button>
                      </Link>
                    </Card.Footer>
                  </Card>
                }
              </Col>
            )}
          </Row>
          <p><b>Notas</b>: <br />Todas as edições para venda referem-se ao ano de 2021.
          </p>
        </Container>
        <Footer />
      </div>
    )
  }
}

export default Livros;
