import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'

import { Container, Row, Col, Card } from 'react-bootstrap'

import cantina from '../img/cantina.webp'
import reprografia from '../img/reprografia.webp'

import Menu from './Menu'
import Footer from './Footer'
import Banner from './Banner'

class Servicos extends Component {

    render() {
        return (
            <div>
                <Menu />
                <Banner titulo="Instalações" subtitulo="Tudo o que precisas" />
                <Container className="py-50">
                    <Row >
                        <Col lg={6} >
                            <Card className="shadow-lg border-0 h-100 text-center" >
                                <Card.Img className="card-img" variant="top" src={cantina} />
                                <Card.Body>

                                    <Card.Title className="font-weight-bold text-uppercase" >Cantina</Card.Title>
                                    <Card.Text>
                                        <b> Horário de Funcionamento:</b>
                                        <br/>
                                        Dias úteis:
                                        <br/>
                                        Abertura: 9:00h
                                        <br/>
                                        Encerramento: 18:30h
                                        <br/>
                                        [Prato do dia de Segunda a Quinta]
                                        <br/>
                                        Fins de Semana e Feriados:
                                        <br/>
                                        Encerrado
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} >
                            <Card className="shadow-lg border-0 h-100 text-center " >
                                <Card.Img className="card-img" variant="top" src={reprografia} />
                                <Card.Body>
                                    <Card.Title className="font-weight-bold text-uppercase" >Reprografia</Card.Title>
                                    <Card.Text>
                                        <b>Horário de Funcionamento:</b>
                                        <br />
                                        Dias úteis:
                                        <br />
                                        Abertura: 9:00h
                                        <br />
                                        Encerramento: 18:30h
                                        <br />
                                        Fins de Semana e Feriados:
                                        <br />
                                        Encerrado
                                </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Footer/>
            </div>
        )
    }
}

export default Servicos;