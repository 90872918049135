import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'
import { Container, Col, Button, Row } from 'react-bootstrap'
import Menu from './Menu';
import Footer from './Footer'
import cartao from '../img/cartao.png'
import menina from '../img/menina.png'


class Protocolos extends Component {
    constructor() {
        super();
    }

    render() {
        return (
            <div>
                <Menu />
                <Container fluid={true} className="cartao">

                    <Container>

                        <Row className="py-5">
                           

                            <Col className="py-5">
                                <h2 className="font-weight-bold">+Ativo com a tua AE</h2>
                                <br/>
                                <p>

                                    <p>
                                    Sabias que com a tua Associação de Estudantes podes usufruir de 5% de desconto no Programa UPFit da Universidade do Porto?
                                    <br/>
                                    <br/>
                                    O Programa UPFit, programa de fitness da Universidade do Porto, disponibiliza mais de 40 sessões de fitness, de segunda a sábado, em dois dos três polos da U.Porto: o Pólo I – CDUP-Boa Hora (Rua da Boa Hora nº 26) e o Pólo II – Faculdade de Desporto.
                                    </p>
                                    <br/>
                                    <h3 className="font-weight-bold">Para utilizares o desconto basta apresentares o nosso código aefdup1920 nos balcões das instalações desportivas.</h3>
                                </p>
                            </Col>

                            <Col>
                                <img src={menina}  />
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={6}>
                            A tua AE dá-te 5% de desconto no cartão UPFit total semestral ou anual, que te permite frequentar todas as sessões de fitness e dança, musculação, atividades aquáticas e corpo e mente.  O cartão pode ser utilizado de segunda- feira a sábado, nas diferentes instalações do Programa UPFit, sendo que nenhum implica qualquer regime de fidelização.
                            <br/>
                            <br/>
                            
                            <Button text="Ver horários"  href="https://cdup.up.pt/upfit/o-programa" variant="outline-light">Ver Horários</Button>

                            </Col>

                          
                           
                        </Row>


                        <br/>
                        <br/>


                        <Row>
                            <Col lg={12}>
                                <p>
                                    <small>

                                    Aproveitamos para informar que este Programa disponibiliza também sessões de Desporto Adaptado, natação e musculação, orientadas por técnicos especializados. Estas sessões devem ser agendadas através de desportoadaptado@cdup.up.pt.
                                    <br />
                                    <br />
                                    Mais recentemente, e porque não há desporto sem saúde, o CDUP-UP passou também a disponibilizar um serviço de consultas de nutrição e avaliação da composição corporal,  no âmbito do  Programa UPFit Saúde.
                                    </small>

                                </p>
                            </Col>  
                        </Row>
                    </Container>
                </Container>
                <Footer />
            </div>
        )
    }
}

export default Protocolos;