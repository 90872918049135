import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'
import { Carousel, Button } from 'react-bootstrap'

import slide1 from '../img/slide1.jpg'

class Slide extends Component {
  constructor() {
    super();
  }
  
  render() {
    return (
        <div>
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={slide1}
                    />       
                    <Carousel.Caption>
                        <h1><strong>Associação de Estudantes</strong></h1>
                        <h3>Faculdade de Direito da Universidade do Porto</h3>
                        <Button className="mt-4" href="/aefdup" variant="danger">Conheça-nos melhor</Button>
                    </Carousel.Caption>  
                </Carousel.Item>
                <Carousel.Item>
                <Carousel.Caption>
                        <h1><strong>Livraria AEFDUP</strong></h1>
                        <h3>Encomenda já o teu livro</h3>
                        <Button className="mt-4" href="/livros" variant="danger">Ver loja</Button>
                    </Carousel.Caption>  
                    <img
                        className="d-block w-100"
                        src='https://images.unsplash.com/photo-1457369804613-52c61a468e7d?ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80'
                    />                 
                </Carousel.Item>
                <Carousel.Item>
                <Carousel.Caption>
                        <h1><strong>+Ativo com a tua AE</strong></h1>
                        <h3>Com a AEFDUP tens descontos incríveis</h3>
                        <Button className="mt-4" href="/protocolos" variant="danger">Saber mais</Button>
                    </Carousel.Caption>  
                    <img
                        className="d-block w-100"
                        src='https://images.unsplash.com/photo-1558611848-73f7eb4001a1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2251&q=80'
                    />                 
                </Carousel.Item>
            </Carousel>
        </div>
      )
  }
}

export default Slide;