import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'

class Banner extends Component {
  constructor() {
    super();
  }
  
  render() {
    return (
        <div>
           <div className="banner">
               <h1 >{this.props.titulo}</h1>
               <h3 >{this.props.subtitulo}</h3>
           </div>
        </div>
      )
  }
}

export default Banner;