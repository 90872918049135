import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'

import {Container} from 'react-bootstrap'

import Menu from './Menu'
import Footer from './Footer';
import Banner from './Banner';


class BancoSebentas extends Component {

  render() {
    return (
        <div>

            <Menu />
            <Banner titulo="Banco de Sebentas" subtitulo="Tudo o que precisas para estudar" />
            <Container className="my-5">

            <iframe src="https://drive.google.com/embeddedfolderview?id=1nFuqzb5lRaL28tssHAK2_aTawXF4G2-R#list" style={{width: "100%", height:"600px", border:"0"}}>

            </iframe>

         
            </Container>
            <Footer />

        </div>
    )
  }
}

export default BancoSebentas;