import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'
import { Container, Card, Col, Row, ListGroup, Button } from 'react-bootstrap'
import { TiDocument } from "react-icons/ti";
import Menu from './Menu'
import Footer from './Footer';
import Banner from './Banner';
import estatutos from '../estatutos.pdf'
import sheets from '../data/sheets'
import Papa from "papaparse";

class Documentos extends Component {

    constructor() {
        super();
        this.state = {
            pareceres: [],
            atas: [],
        };
    }

    componentDidMount() {
        Papa.parse(sheets.documentos, {
            download: true,
            header: true,

            complete: (results) => {

                results.data.reverse();
                
                let pareceres = [];
                let atas = [];

                results.data.forEach(element => {
                    if (element.tipo == "parecer") {
                        pareceres.push(element);
                    } else if (element.tipo == "ata") {
                        atas.push(element);
                    }
                });

                this.setState({
                    pareceres, atas
                });

            },
        });
    }


    render() {
        return (
            <div>

                <Menu />
                <Banner titulo="Documentos" subtitulo="Estatutos, Atas das RGAS e Pareceres do Conselho Fiscal" />
                <Container className="my-5">

                    <p>Manter o nosso corpo estudantil informado é uma das nossas prioridades. Podes utilizar esta página para ficares a par do trabalho dos órgãos sociais da tua Associação.</p>

                    <div className="text-center p-5">
                        <Button href={estatutos} target="_blank" variant="outline-danger">Consulta aqui os estatutos da Associação</Button>
                    </div>

                    <Row className="documentos">

                        <Col lg={6} >
                            <Card className="shadow-lg border-0 h-100 text-center py-5" >
                                <Card.Body>
                                    <div className="icon-rounded">
                                        <span className="icon">
                                            <TiDocument />
                                        </span>
                                    </div>
                                    <Card.Title className="font-weight-bold text-uppercase pb-4" >Atas RGAS</Card.Title>
                                    <Card.Text>
                                        <ListGroup variant="flush" >
                                            {this.state.atas.map((ata) =>
                                                <ListGroup.Item><a target="_blank" href={ata.link}><TiDocument size={20} /> {ata.nome}</a></ListGroup.Item>
                                            )}
                                        </ListGroup>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={6} >
                            <Card className="shadow-lg border-0 h-100 text-center  py-5" >
                                <Card.Body>
                                    <div className="icon-rounded">
                                        <span className="icon">
                                            <TiDocument />

                                        </span>
                                    </div>
                                    <Card.Title className="font-weight-bold text-uppercase pb-4" >Pareceres do Conselho Fiscal</Card.Title>
                                    <Card.Text>
                                        <ListGroup variant="flush" >
                                            {this.state.pareceres.map((parecer) =>
                                                <ListGroup.Item><a target="_blank" href={parecer.link}><TiDocument size={20} /> {parecer.nome}</a></ListGroup.Item>
                                            )}
                                        </ListGroup>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>


                </Container>
                <Footer />

            </div>
        )
    }
}

export default Documentos;