import React, { Component } from 'react';
import '../stylesheets/bootstrap.css';
import '../stylesheets/main.css'
import Logo from '../img/logo.png'
import {  Card, Row, Col, Navbar, NavDropdown, Form, Nav, Container, Button } from 'react-bootstrap'

import Dashboard from './Dashboard'
import Login from './Login'

import fire  from '../config/Fire'

class Admin extends Component {
    constructor() {
        super();
        this.authListener = this.authListener.bind(this);
        this.state = {
          userAuth: null,
        };
    }

    componentDidMount() {   
      this.authListener();
    }
  
    authListener() { 
      fire.auth().onAuthStateChanged((u) => {
        if (u) { 
          this.setState({ userAuth: u });
        } else {
          this.setState({ userAuth: null });
        }
      });  
    }


  render() {
    return (
      <div>
        {this.state.userAuth ? (<Dashboard />) : (<Login />)}
      </div>
    )
  }
}

export default Admin;